
export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  1: {
    MULTICALL_ADDRESS: "0xcA11bde05977b3631167028862bE2a173976CA11",
    DIVIDEND_ADDRESS: "0xE9e48031209BB49Ee67a343D7A065692DfC943F3",
    TOKEN_ADDRESS: "0x9050dFBAA4999F36EeF9F4a56df90Ba6440a13f2",
  },
  'default': {
    MULTICALL_ADDRESS: "0xcA11bde05977b3631167028862bE2a173976CA11",
    TOKEN_ADDRESS: "0x9050dFBAA4999F36EeF9F4a56df90Ba6440a13f2",
    DIVIDEND_ADDRESS: "0xE9e48031209BB49Ee67a343D7A065692DfC943F3",
  }

}


export const MAINNET_RPC = "https://ethereum.publicnode.com";
export const ETH_LINK_ADDRESS = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419";


export const formatPrice = (num) => {
  try {
    return new Intl.NumberFormat('ja-JP').format(parseFloat(num).toFixed(18));
  }
  catch (err) {
    console.log(err.message);
    return 0;
  }
}
