import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import { getDividendContract, getTokenContract, MulticallContractWeb3 } from "../hooks/contractHelper";
import { toast } from "react-toastify";
import chainLinkAbi from '../json/chainlink.json';
import { ETH_LINK_ADDRESS } from "../hooks/constant";
import { getMainnetWeb3 } from "../hooks/connectors";


export const useCommonStats = (updater) => {
    let { chainId } = useWeb3React();
    let mainWeb3 = getMainnetWeb3();

    const [stats, setStats] = useState({
        totalDividends: 0,
        totalShares: 0,
        totalReserved: 0,
        ethPrice: 0,
        totalDistributed: 0,
        totalRewardDistributed: 0
    });

    let eth_link_contract = new mainWeb3.eth.Contract(chainLinkAbi, ETH_LINK_ADDRESS);
    let dividendContract = getDividendContract(chainId);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3(
                    [
                        dividendContract.methods.totalDividends(), //0
                        dividendContract.methods.totalShares(), //1
                        dividendContract.methods.totalReserved(), //2
                        dividendContract.methods.totalDistributed(), //3
                    ],
                    chainId
                );

                const mainData = await eth_link_contract.methods.latestRoundData().call();
                let ethUsdPrice = mainData[1] / Math.pow(10, 8);

                console.log()
                setStats({
                    totalDividends: data[0] / Math.pow(10, 18),
                    totalShares: data[1] / Math.pow(10, 18),
                    totalReserved: data[2] / Math.pow(10, 18),
                    ethPrice: ethUsdPrice,
                    totalDistributed: data[3] / Math.pow(10, 18),
                    totalRewardDistributed: (parseFloat(data[3] / Math.pow(10, 18)) - parseFloat(data[2] / Math.pow(10, 18))) * parseFloat(ethUsdPrice)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (dividendContract && eth_link_contract) {
            fetch();
        }

        return () => {
            setStats({
                totalDividends: 0,
                totalShares: 0,
                totalReserved: 0,
                ethPrice: 0,
                totalDistributed: 0,
                totalRewardDistributed: 0
            })
        }



        // eslint-disable-next-line
    }, [updater, chainId]);

    return stats;
}


export const useAccStats = (updater) => {
    let { chainId, account } = useWeb3React();

    const [stats, setStats] = useState({
        getUnpaidEarning: 0,
        balanceOf: 0,
        amount: 0,
        totalExcluded: 0,
        totalRealised: 0,
        reserved: 0
    });


    let tokenContract = getTokenContract(chainId);
    let dividendContract = getDividendContract(chainId);





    useEffect(() => {
        const fetch = async () => {
            try {
                const accData = await MulticallContractWeb3([
                    dividendContract.methods.getUnpaidEarning(account), //0
                    tokenContract.methods.balanceOf(account), //1
                    dividendContract.methods.shares(account), //2
                ], chainId);


                setStats({
                    getUnpaidEarning: accData[0] / Math.pow(10, 18),
                    balanceOf: accData[1] / Math.pow(10, 9),
                    amount: accData[2][0] / Math.pow(10, 18),
                    totalExcluded: accData[2][1] / Math.pow(10, 18),
                    totalRealised: accData[2][2] / Math.pow(10, 18),
                    reserved: accData[2][3] / Math.pow(10, 18)

                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (account) {

            fetch();
        }

        return () => {
            setStats({
                getUnpaidEarning: 0,
                balanceOf: 0,
                amount: 0,
                totalExcluded: 0,
                totalRealised: 0,
                reserved: 0
            })
        }


        // eslint-disable-next-line
    }, [updater, chainId, account]);

    return stats;
}
