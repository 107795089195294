import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { injected, walletconnect , coinbaseWallet } from "./hooks/connectors";
import { trimAddress } from './hooks/constant';
import useEagerConnect from './hooks/useWeb3';
import localStorage from "local-storage";
import metamaskImg from './images/metamask.png';
import walletImg from './images/wallet-cannect.png';
import coinbaseImg from './images/cb.png';
import { supportNetwork } from "./hooks/network";



export const Connect = function () {
    const context = useWeb3React();
    const { connector, account, deactivate, active, error , activate } = context;



    useEffect(() => {
        if (account) {
            localStorage.set('address', account);
        }
    }, [account])


    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEagerConnect();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);


    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            const dappUrl = window.location.origin; // TODO enter your dapp URL. 
            let metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
            window.open(metamaskAppDeepLink)
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={(e) => switchNetwork(5)}>Switch Network</span>;
        }

        deactivate();
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>


            {
                error &&
                <button type="button" className="btn btn-primary ms-auto" onClick={() => {
                    setActivatingConnector();
                }}>
                    {getErrorMessage(error)}
                </button>
            }
            {!error &&
                <>


                    {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                        <button type="button" className="btn btn-primary ms-auto" onClick={() => {
                            localStorage.remove('address');
                            deactivate()
                        }}>
                            {account && trimAddress(account)}
                        </button>
                    }
                    {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                        <button type="button" className="btn btn-primary ms-auto" data-bs-toggle="modal" data-bs-target="#exampleModal" aria-controls="offcanvasEnd">
                            {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet) ) ? <span className="btn-text">Connecting...</span> : <span className="btn-text">Connect Wallet</span>}
                        </button>
                    }
                </>
            }

            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Connect a Wallet</h1>
                            <button type="button" id="btn-close" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <button
                                class="cursor-pointer disabled:cursor-auto text-md transition w-full py-2 rounded-full border flex items-center justify-center text-center border-brand-default bg-brand-default hover:border-brand-hovered hover:bg-brand-hovered text-white px-3 mb-8"
                                id="connect-wallet-button"
                                onClick={() => {
                                    activate(injected);
                                    document.getElementById("btn-close").click()
                                }}
                            >
                                <span class="flex mr-2.5 items-center">
                                    <img src={metamaskImg} height={40} width={40} class="h-6 w-6 mx-3" alt="" />
                                </span>
                                MetaMask
                            </button>
                            <button
                                class="cursor-pointer disabled:cursor-auto text-md transition w-full py-2 rounded-full border flex items-center justify-center text-center border-brand-default bg-brand-default hover:border-brand-hovered hover:bg-brand-hovered text-white px-3 mb-8"
                                id="connect-wallet-button"
                                onClick={() => {
                                    activate(walletconnect);
                                    document.getElementById("btn-close").click()
                                }}
                            >
                                <span class="flex mr-2.5 items-center">
                                    <img src={walletImg} height={40} width={40} class="h-6 w-6 mx-3" alt="" />
                                </span>
                                Wallet Connect
                            </button>
                            <button
                                class="cursor-pointer disabled:cursor-auto text-md transition w-full py-2 rounded-full border flex items-center justify-center text-center border-brand-default bg-brand-default hover:border-brand-hovered hover:bg-brand-hovered text-white px-3 mb-8"
                                id="connect-wallet-button"
                                onClick={() => {
                                    activate(coinbaseWallet);
                                    document.getElementById("btn-close").click()
                                }}
                            >
                                <span class="flex mr-2.5 items-center">
                                    <img src={coinbaseImg} height={40} width={40} class="h-6 w-6 mx-1" alt="" />
                                </span>
                                Coinbase Wallet
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    );
};

export default Connect;