import React, { useState } from 'react'
import Connect from './Connect';
import { contract, formatPrice, tokenOptions } from './hooks/constant';
import { useAccStats, useCommonStats } from './stats/useStats';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import { getContract, getCustomTokenContract } from './hooks/contractHelper';
import dividendAbi from './json/dividend.json';
import { getWeb3 } from './hooks/connectors';
import logoImg from './images/logo.png';
import telegramImg from './images/telegram_blue.svg';
import twitterImg from './images/twitter_blue.svg';
import emailImg from './images/download.png';
import Select, { components } from 'react-select';
import { Link } from 'react-router-dom';
import { supportNetwork } from './hooks/network';



export default function Home() {
    const [updater, setUpdater] = useState(1);
    const accStats = useAccStats(updater);
    const stats = useCommonStats(updater);
    const { account, chainId, library } = useWeb3React();
    const [loading, setLoading] = useState(false);
    const [cloading, setCLoading] = useState(false);
    const [caddress, setCaddress] = useState('');
    const [ccaddress, setCcaddress] = useState('');
    const [tname, setTname] = useState('');
    const [tsymbol, setTsymbol] = useState('');
    const [tdecimals, setTdecimals] = useState('');
    const [error, setError] = useState('');
    const [cdisabled, setCdisabled] = useState(true);
    let web3 = getWeb3(chainId)
    const { Option, SingleValue } = components;

    const handleClaim = async () => {
        setLoading(true);
        if (account) {
            if (chainId) {
                try {
                    let divContract = getContract(dividendAbi, contract[chainId].DIVIDEND_ADDRESS, library);
                    let tx = await divContract.claim({ 'from': account });
                   
                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading(false);
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false)
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading(false)
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading(false)
        }
    }




    return (
        <>

            <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img src={logoImg} alt="ether logo" className='brand-logo' />
                    </Link>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a href={supportNetwork[chainId] && supportNetwork[chainId].buynowurl ? supportNetwork[chainId].buynowurl : supportNetwork['default'].buynowurl} rel="noreferrer" target="_blank" className="btn btn-primary ms-auto" >
                                    Buy Now
                                </a>
                            </li>
                            <li class="nav-item">
                                <Connect />
                            </li>
                            <li class="nav-item mobile-set">
                                <a href='https://t.me/halo_protocol' rel="noreferrer" target="_blank" className='mx-3'>
                                    <img src={telegramImg} height="24px" width="24px" alt="img-social" />
                                </a>
                                <a href='https://X.com/halo_protocol' rel="noreferrer" target="_blank" className='mx-3'>
                                    <img src={twitterImg} height="24px" width="24px" alt="img-social" />
                                </a>
                           {/* <a href='mailto:hi@yogitoken.org' className='mx-3'>
                                    <img src={emailImg} height="24px" width="24px" alt="img-social" />
                                </a>*/}
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>

            <section className='banner-section'>
                <div className="container-fluid">
                    <div className='row d-flex justify-content-center'>
                        <div className='col-12 col-sm-12 col-lg-8'>
                            <h2 className='title text-center'>Halo Protocol Emission System</h2>
                            <p className='sub-title text-center'>The ETH rewards for holders is stored in the Emission System Contract. When you want to claim your ETH rewards, click Claim below.</p>

                            <div className='row d-flex justify-content-sm-center'>
                                <div className='col-12 col-sm-8 col-md-12 col-xl-8 ' style={{ "fontSize": "13px" }}>
                                    <div className='card info-card mt-4 mb-4'>
                                        <div className='card-body text-white'>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>YOUR TOKEN BALANCE: </span>
                                                <span> {accStats.balanceOf ? formatPrice(accStats.balanceOf) : 0} $HALOP</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>TOTAL ETH REWARDS DISTRIBUTED: </span>
                                                <span> $ {formatPrice(stats.totalRewardDistributed)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>YOUR PENDING ETH REWARDS: </span>
                                                <span> $ {formatPrice((parseFloat(accStats.getUnpaidEarning)) * parseFloat(stats.ethPrice))}</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>YOUR TOTAL ETH REWARDS CLAIMED: </span>
                                                <span>$ {formatPrice((parseFloat(accStats.totalRealised) - parseFloat(accStats.reserved)) * parseFloat(stats.ethPrice))}</span>
                                            </div>
                                            <button disabled={loading} className='btn btn-primary w-100 mt-3' type='button' onClick={() => handleClaim()} >
                                                {loading ? 'loading...' : 'Claim'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <footer className='footer'>
                <p className='text-center'>
                    © Halo Protocol 2023 All Rights Reserved.
                </p>
            </footer>



        </>
    )
}
