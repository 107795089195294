import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import Web3 from "web3";
import { supportNetwork, RPC_URLS } from './network';
import { MAINNET_RPC } from "./constant";

export const infura_Id = "4ae2bfdaa80046559b446a1c28ec71fc";

export const getWeb3 = (chainId = "default") => {
  return new Web3(supportNetwork[chainId] ? supportNetwork[chainId].rpc : supportNetwork['default'].rpc);
}

export const getMainnetWeb3 = () => {
  return new Web3(MAINNET_RPC);
}

export const supportChainId = Object.keys(supportNetwork).map(function (key) {
  return parseInt(key);
});



export const injected = new InjectedConnector({
  supportedChainIds: supportChainId
})

export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  qrcode: true,
  infuraId: infura_Id,
});


export const coinbaseWallet = new WalletLinkConnector({

  url: RPC_URLS,
  appName: "Halo Emissions System",
  supportedChainIds: supportChainId,

});
