export const supportNetwork = {
  1: {
    name: "ETH",
    chainId: 1,
    rpc: "https://mainnet.infura.io/v3/4ae2bfdaa80046559b446a1c28ec71fc",
    symbol: "ETH",
    buynowurl : "https://app.uniswap.org/swap?outputCurrency=0x9050dFBAA4999F36EeF9F4a56df90Ba6440a13f2&chain=ethereum"
  },
  default: {
    name: "ETH",
    chainId: 1,
    rpc: "https://mainnet.infura.io/v3/4ae2bfdaa80046559b446a1c28ec71fc",
    symbol: "ETH",
    buynowurl : "https://app.uniswap.org/swap?outputCurrency=0x9050dFBAA4999F36EeF9F4a56df90Ba6440a13f2&chain=ethereum"
  }
};

export const RPC_URLS = {
  1: "https://mainnet.infura.io/v3/4ae2bfdaa80046559b446a1c28ec71fc"
};
